import React, { useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropertyCardDetailed from '../../PropertyCardDetailed/PropertyCardDetailed';
import ScrollAnimation from 'react-animate-on-scroll';

import resultImg1 from '../../../images/result-img-7.jpg';
import resultImgMob1 from '../../../images/result-img-7-mob.jpg';
import resultImg2 from '../../../images/result-img-8.jpg';
import resultImgMob2 from '../../../images/result-img-8-mob.jpg';
import resultImg3 from '../../../images/result-img-9.jpg';
import resultImg4 from '../../../images/result-img-10.jpg';
import resultLogo1 from '../../../images/result-logo-1.jpg';
import resultLogo2 from '../../../images/result-logo-2.jpg';
import resultLogo3 from '../../../images/result-logo-3.jpg';
import resultLogo4 from '../../../images/result-logo-4.jpg';
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect";
import './moreResults.scss'

const MoreResults =() => {
const propertyData = [
	{
		id: 1,
		propertyImg: resultImg1,
		propertyImgMob: resultImgMob1,
		totalImages: 30,
		videoUrl: '/',
		isSaved: false,
		propertyName: 'Sandymount Castle Court – ',
		propertyName2: 'Sandymount, Dublin 4 D04 E9V6',
		propertyLogo: resultLogo1,
		propertyDetails: '2-5 Bedroom Apartments',
		availableProperties: '9 Available',
		propertyPrice: '€255,000 – €485,000',
		moreDetails: 'DNG are delighted to present Avonmore View, a modern and elegant new development by Ridgeway Homes in Rathdrum Co. Wicklow. Rathdrum is a vibrant and friendly town, steeped in history and nestled in the heart of the Garden of Ireland.',
		bedroomCount: 5,
		bathroomCount: 1,
		loungeCount: 1,
		propertyArea: 97,
		propertyTag: "coming soon",
	},
	{
		id: 2,
		propertyImg: resultImg2,
		propertyImgMob: resultImgMob2,
		totalImages: 17,
		isSaved: false,
		propertyName: 'Rockville – ',
		propertyName2: 'Glenamuck Road, Dublin 18',
		propertyLogo: resultLogo2,
		propertyDetails: '1-4 Bedroom Apartments',
		availableProperties: '11 Available',
		propertyPrice: '€169,500 – €380,000',
		moreDetails: 'DNG are delighted to present Avonmore View, a modern and elegant new development by Ridgeway Homes in Rathdrum Co. Wicklow. Rathdrum is a vibrant and friendly town, steeped in history and nestled in the heart of the Garden of Ireland.',
		bedroomCount: 3,
		bathroomCount: 2,
		loungeCount: 1,
		propertyArea: 123,
		propertyTag: "",
	},
	{
		id: 3,
		propertyImg: resultImg3,
		totalImages: 18,
		isSaved: false,
		propertyName: 'Sandymount Castle Court – ',
		propertyName2: 'Sandymount, Dublin 4 D04 E9V6',
		propertyLogo: resultLogo3,
		propertyDetails: '2-5 Bedroom Apartments',
		availableProperties: "10 Available",
		propertyPrice: '€255,000 – €485,000',
		moreDetails: 'DNG are delighted to present Avonmore View, a modern and elegant new development by Ridgeway Homes in Rathdrum Co. Wicklow. Rathdrum is a vibrant and friendly town, steeped in history and nestled in the heart of the Garden of Ireland.',
		bedroomCount: 3,
		bathroomCount: 2,
		loungeCount: 1,
		propertyArea: 123,
		propertyTag: "coming soon",
	},
	{
		id: 4,
		propertyImg: resultImg4,
		totalImages: 18,
		isSaved: false,
		propertyName: 'Rockville – ',
		propertyName2: 'Glenamuck Road, Dublin 18',
		propertyLogo: resultLogo4,
		propertyDetails: '2-5 Bedroom Apartments',
		availableProperties: "10 Available",
		propertyPrice: '€255,000 – €485,000',
		moreDetails: 'DNG are delighted to present Avonmore View, a modern and elegant new development by Ridgeway Homes in Rathdrum Co. Wicklow. Rathdrum is a vibrant and friendly town, steeped in history and nestled in the heart of the Garden of Ireland.',
		bedroomCount: 3,
		bathroomCount: 2,
		loungeCount: 1,
		propertyArea: 123,
		propertyTag: "coming soon",
	},
]



let [reviewList, setCustomerReview] = useState([]);
let intialNumberOfReview = 2;

useEffect(() => {
  if (isMobileOnly) {
	intialNumberOfReview = 2;
  } else if (isTablet || isIPad13 || isIOS13) {
	intialNumberOfReview = 4;
  }
}, []);

useEffect(() => {
  setCustomerReview(propertyData.slice(0, intialNumberOfReview));
}, []);




	return (
		<section className="more-results">
			<Container>
				<Row>
					<Col sm="12">
						<div className="result-block">
							{reviewList.map(property =>  <PropertyCardDetailed  propertyData={property}/>)}
						</div>
						<div className="more-btn">
							<button type="button" className="btn">load more</button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default MoreResults;
