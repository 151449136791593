import React from 'react';
import { Link } from 'gatsby';
import './propertyCard.scss';
import ScrollAnimation from 'react-animate-on-scroll';



const PopertyCard = (props) => {
	const propertyData = props.propertyData;
	return (
		<ScrollAnimation animateIn="fadeInUp">
			<div className='property-card'>
				<div className="property-card-wrapper">
					<div className="property-img">
						<img src={propertyData.propertyImg} alt="property" />
						<div className="property-wrapper">
							<div className="left-block">
								<div className="image-wrap">
									<i className="icon-camera"></i>
									<div className="image-count">1/{propertyData.totalImages}</div>
								</div>
								<div className="property-view">
									{propertyData.videoUrl && <div className="video-wrap">
										<button>
											<i className="icon-play"></i>
										</button>
									</div>}
									<div className="floor-plan">
										<button>
											<i className="icon-floor-plan"></i>
										</button>
									</div>
								</div>
							</div>
							<div className="right-block">
								<button>
									<i className={propertyData.isSaved ? 'icon-heart filled' : 'icon-heart' } />
									<span className="span-btn-text d-none d-md-inline-block">{propertyData.isSaved ? 'saved' : 'save' }</span>
								</button>
							</div>
						</div>
						{propertyData.propertyTag && <div className="property-tag">{propertyData.propertyTag}</div>}
					</div>
					<div className="property-details">
						<div className="property-name"><Link to="/">{propertyData.propertyName}<br />{propertyData.propertyName2}</Link></div>
						<p>{propertyData.propertyDetails}</p>
						<div className="property-price">{propertyData.propertyPrice}</div>
						<div className="bottom-block">
							<div className="bedroom-count">
								<i className="icon-bed"></i>{propertyData.bedroomCount}
							</div>
							<div className="bathroom-count">
								<i className="icon-tub"></i>{propertyData.bathroomCount}
							</div>
							<div className="lounge-count d-none d-xl-flex">
								<i className="icon-couch"></i>{propertyData.loungeCount}
							</div>
							<div className="property-area">
								<i className="icon-cube"></i>{`${propertyData.propertyArea}m`}<sup>2</sup>
							</div>
							<div className="property-label">
								<img src={propertyData.propertyLabel} alt="label" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</ScrollAnimation>
	)
}

export default PopertyCard;
