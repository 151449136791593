import React from 'react';
import { Link } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';

import './propertyCardDetailed.scss';



const PropertyCardDetailed = (props) => {
	const propertyData = props.propertyData;

	return (
		<ScrollAnimation animateIn="fadeInUp">
			<div className="property-card-detailed">
				<div className="property-img">
					{propertyData.propertyImgMob ? <picture>
						<source media="(min-width: 1200px)" srcSet={propertyData.propertyImg} />
						<img src={propertyData.propertyImgMob} alt="property" />
					</picture> :
						<img src={propertyData.propertyImg} alt="property" />
					}
					<div className="property-wrapper">
						<div className="left-block">
							<div className="image-wrap">
								<i className="icon-camera"></i>
								<div className="image-count">1/{propertyData.totalImages}</div>
							</div>
							<div className="property-view">
								{propertyData.videoUrl && <div className="video-wrap">
									<button>
										<i className="icon-play"></i>
									</button>
								</div>}
								<div className="floor-plan">
									<button>
										<i className="icon-floor-plan"></i>
									</button>
								</div>
							</div>
						</div>
						<div className="right-block">
							<button>
								<i className={propertyData.isSaved ? 'icon-heart filled' : 'icon-heart' } />
								<span className="span-btn-text">{propertyData.isSaved ? 'saved' : 'save' }</span>
							</button>
						</div>
					</div>
					{propertyData.propertyTag && <div className="property-tag">{propertyData.propertyTag}</div>}
				</div>
				<div className="property-details">
					<div className="name-wrap">
						<div className="property-name"><Link to="/">{propertyData.propertyName}<br className="d-none d-xl-block" />{propertyData.propertyName2}</Link></div>
						<div className="img-wrap">
							<img src={propertyData.propertyLogo} alt="logo" />
						</div>
					</div>
					<p>{propertyData.propertyDetails} <span className="available-properties">{propertyData.availableProperties}</span></p>
					<div className="property-price">{propertyData.propertyPrice}</div>
					<p className="more-info">{propertyData.moreDetails}</p>
					<div className="links-block">
						<div className="link-wrap">
							<Link to="/">
								View Details
								<i className="icon-arrow"></i>
							</Link>
						</div>
						<div className="link-wrap">
							<Link to="/">
								Register Your Interest
								<i className="icon-arrow"></i>
							</Link>
						</div>
						<div className="link-wrap">
							<a href="tel:719159222">
								<i className="icon-phone"></i>
								071 915 9222
							</a>
						</div>
					</div>
				</div>
			</div>
		</ScrollAnimation>
	)
}

export default PropertyCardDetailed;
